import React from "react"
import { Link } from "gatsby"

const Footer = ({ siteTitle }) => (
  <footer>
    ©{new Date().getFullYear()} MAX CUAP
  <Link to="/impressum">
      Impressum
  </Link>

  </footer>
)

export default Footer
