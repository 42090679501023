// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-max-js": () => import("./../src/pages/max.js" /* webpackChunkName: "component---src-pages-max-js" */),
  "component---src-pages-mudd-js": () => import("./../src/pages/mudd.js" /* webpackChunkName: "component---src-pages-mudd-js" */),
  "component---src-pages-organization-js": () => import("./../src/pages/organization.js" /* webpackChunkName: "component---src-pages-organization-js" */),
  "component---src-pages-tickets-js": () => import("./../src/pages/tickets.js" /* webpackChunkName: "component---src-pages-tickets-js" */)
}

