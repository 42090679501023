/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "../components/header"
import Footer from "../components/footer"
import Background from "../components/background"

import "./reset.css"
import "./layout.css"
import "./text.css"

const Layout = (props) => {
  return (
    <>
      <Header className={props.uri === "/" ? "home" : ""} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1200,
          width: `100%`,
          boxSizing: `border-box`,
          paddingTop: 0
        }}
      >

        <Background className={props.uri === "/" ? "home" : ""} />
        <main className={props.uri === "/" ? "home" : ""}>
          {props.children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
