import React from "react"
import styles from "./logo.module.css"
import LogoSVG from "../svgs/logo.svg"
import { Link } from "gatsby"

class Logo extends React.Component {

    render() {
        return <Link to="/">

            <div className={styles.logoContainer + " logo-container"}>
                <canvas width="337.2" height="61.9"></canvas>
                <LogoSVG className={styles.logo} />
            </div></Link>
    }
}

export default Logo