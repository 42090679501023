import PropTypes from "prop-types"
import React from "react"
import Logo from "../components/logo"

const Header = ({ className }) => (
  <header className={className}>
    <Logo />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
