import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styles from "./background.module.css"

import BackgroundImage from 'gatsby-background-image'

const Background = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "background.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <div className={className}>
          <div className={styles.grid + " grid"} />
          <BackgroundImage
            Tag="div"
            className={styles.background}
            fluid={imageData}
            backgroundColor={`#8d7c68`}
            preserveStackingContext={true}
          >

          </BackgroundImage>
          <BackgroundImage
            Tag="div"
            className={styles.foreground}
            fluid={imageData}
            backgroundColor={`#8d7c68`}
            preserveStackingContext={true}
          >

          </BackgroundImage>
        </div>

      )
    }}
  />
)


export default Background